import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from "../../views/Dashboard/Sidebar"
import Header from "../../views/Dashboard/Header"
import Circuit from './../../static/images/icon/mob.svg'
import OS from './../../static/images/icon/os.svg'

import './../../static/css/dashboard.css'
import logo from './../../static/images/logo3.png'
import $ from 'jquery'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var token
var jsonPayload
class Main extends Component {
    constructor(props) {
        super(props)
        this._logout = this._logout.bind(this)
        if (localStorage.getItem('secretkey')) {
            // jwtDecode(localStorage.getItem('secretkey'))
            jsonPayload = localStorage.getItem('secretkey')
            var base64Url = jsonPayload.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            token = JSON.parse(decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join('')))

        }
        if(token){
            // console.log(token);
            this.definedArray()
        }


    }
    // permission = {
    //     viewSendCommand: true,
    //     viewSender: true,
    //     viewDevice: false,
    //     viewSmsDevice: true,
    // }

    componentDidMount() {
        $("[data-toggle='dropdown']").on("click", function (e) {
          e.stopPropagation();
          let target = $(this).data('target')
          $(target).parent().toggleClass('open')
        });

        // setTimeout(() => {
        //   $(".custom-modal-overlay").height("100vh");
        // }, 1000);

        // $("[data-toggle!='dropdown']").on("click", function () {
        //   $(".btn-group,.media").removeClass('open')
        // })

        // $("#menuToggle").on("click", function () {
        //   $(".sidebar").toggleClass('open')
        // })
    }

    _logout() {
        // localStorage.removeItem('secretkey')
        // localStorage.removeItem('user')
        // history.push("/")
    }
    colArray = []
    definedArray() {
        this.colArray = [
            {
                title: 'Devices',
                icon: 'fa fa-server',
                path: 'device',
                permission: token.permissions.viewDevice
            },
            {
                title: 'Send Command',
                icon: 'fa fa-th-large',
                path: 'sms_command',
                permission: token.permissions.viewSendCommand
            },
            {
                title: 'Send SMS',
                icon: 'fa fa-comment',
                path: 'sms',
                permission: token.permissions.viewSmsDevice
            },
            // {
            //     title: 'Send Command',
            //     icon: 'fa fa-info',
            //     path: 'sms'
            // },
            {
                title: 'SMS Sender Device',
                icon: 'fa fa-cog',
                path: 'sms_Sender',
                permission: token.permissions.viewSender
            },
            // {
            //     title: 'User',
            //     icon: 'fa fa-info',
            //     path: 'sms'
            // },
        ]
    }


    render() {
        return (
            <div>
                {/* <Header handleClick={this._logout} image={logo} /> */}
                <div className="main-content">
                    <Sidebar image={logo} {...this.props} />
                    <div className="content-body1 container" >


                        <div className="x-3" >
                            <div className="card-header text-start">
                                <div className='row'>
                                    {(() => {
                                        let column = [];
                                        this.colArray.forEach((e, i) => {
                                            if (e.permission) {
                                                column.push(
                                                    <div className='col-3 my-2 hvr-float-shadow' key={i}>
                                                        <Link to={e.path}>
                                                            <div className='card p-4 boxx'>
                                                                <div>
                                                                    <i style={{ fontSize: '20px' }} className={e.icon}></i>
                                                                </div>
                                                                <h6 >{e.title}</h6>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                )
                                            }
                                        })
                                        return column;
                                    })()
                                    }
                                </div>
                            </div>
                        </div>
                        <img style={{ position: 'absolute', opacity: 0.6, left: '3%', transform: 'rotateY(180deg)' }} src={OS} alt='' />
                        <img style={{ position: 'absolute', opacity: 0.6, right: '8%' }} src={Circuit} alt='' />
                    </div>
                    <ToastContainer position="bottom-center" autoClose={false} closeOnClick={false} />
                </div>
            </div>
        )
    }
}

export default Main;