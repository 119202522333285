import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './style.css';
import './../../static/css/workforce.css';
import './../../static/css/attendance.css';
import { useSelector, useDispatch } from 'react-redux';
import { messageListSelector, statusSelector } from '../../state/redux/sms/selectors';
import { callAddDevice, callGetDevice, callOpStatusApi } from '../../state/redux/sms/actions'
import { useForm } from 'react-hook-form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import Loading from 'react-fullscreen-loading';

const Device = () => {
    const dispatch = useDispatch();

    const devices = useSelector(state => state.message.devices);

    const { data: deviceList, error, total_count: totalDevices, loading } = devices;

    const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm();


    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const [tabType, setTabType] = useState('lynktac');

    const totalPages = totalDevices / 100;

    const [show, setShow] = useState(false);

    const [searchableData, setSearchableData] = useState("");

    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);

    const handleSearch = () => {
        dispatch(callGetDevice({
            "project": tabType,
            "searchType": "imei",
            "searchValue": searchValue,
            "page": currentPage,
            "limit": 100
        }));
    }

    const clearSearch = () => {
        setSearchValue('');
    }

    useEffect(() => {
        dispatch(callGetDevice({
            "project": tabType,
            "searchType": "imei",
            "searchValue": searchValue,
            "page": currentPage,
            "limit": 100
        }));
    }, [searchValue == ''])

    const handleRefresh = (event) => {
        setCurrentPage(1);
        setSearchableData("")
        setTabType(event);
        setSearchValue('')
        switch (event) {
            case 'others':
                dispatch(callGetDevice({
                    "project": event,
                    "searchType": "imei",
                    "searchValue": "",
                    "page": 1,
                    "limit": 100
                }));
                dispatch(callOpStatusApi({}))
                return;
            case 'lynkgrid':
                dispatch(callGetDevice({
                    "project": event,
                    "searchType": "imei",
                    "searchValue": "",
                    "page": 1,
                    "limit": 100
                }));
                return;
            // case 'lynkID':
            //     dispatch(callGetDevice({
            //         "project": event,
            //         "searchType": "imei",
            //         "searchValue": "",
            //         "page": currentPage,
            //         "limit": 100
            //     }));
            //     return;
            default:
                dispatch(callGetDevice({
                    "project": event,
                    "searchType": "imei",
                    "searchValue": "",
                    "page": 1,
                    "limit": 100
                }));
                return;
        }
    }

    const handleClearFilter = () => {
        setSearchValue('')
        handleRefresh(tabType);
    }

    useEffect(() => {
        handleRefresh('lynktrac');
    }, [])

    const handleAddDevice = (data) => {
        dispatch(callAddDevice(data));
        handleRefresh(tabType);
        handleClose();
        reset();
    }

    function handlePageChange(page) {
        setCurrentPage(page.selected + 1);
        dispatch(callGetDevice({
            "project": tabType,
            "searchType": "imei",
            "searchValue": searchValue,
            "page": page.selected + 1,
            "limit": 100
        }));
    }

    return (
        <>
            <div className='contentpanel'>
                <Loading loading={loading} background="#ddebe359" loaderColor="#ff7200" />
                <div className='cust-row'>
                    <div className='cust-col-10' >
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <h6 className='text-center' style={{ fontWeight: '900' }}>Device Dashboard</h6>
                                <Link to={'/main'}>
                                    <button className="btn btn-secondary">
                                        <i className="fa fa-arrow-left"></i> Back
                                    </button>
                                </Link>
                            </div>
                            <div className='card-body'>
                                <Tabs
                                    defaultActiveKey="lynktrac"
                                    id="fill-tab-example"
                                    className="mb-3 textt"
                                    style={{
                                        backgroundColor: '#FF7200'
                                    }}
                                    fill
                                    onSelect={(e) => handleRefresh(e)}
                                >
                                    {(() => {
                                        let tabss = [];
                                        let tabArray = [
                                            { title: 'lynktrac', eventKey: 'lynktrac' },
                                            { title: 'lynkgrid', eventKey: 'lynkgrid' },
                                            // { title: 'lynkID', eventKey: 'lynkID' },
                                            { title: 'others', eventKey: 'other' },
                                        ]
                                        tabArray.forEach((e, i) => {
                                            tabss.push(
                                                <Tab eventKey={e.eventKey} title={`Fetch from ${e.title}`} key={i} >
                                                    {/* { e.eventKey == 'lynktrac' || e.eventKey == 'lynkgrid' ? */}
                                                    <div className='card' hidden={e.eventKey != tabType}>
                                                        <div className='card-body'>
                                                            <div className='cust-row'>
                                                                <div className='col-9'>
                                                                    <ReactPaginate
                                                                        previousLabel="Previous"
                                                                        nextLabel="Next"
                                                                        pageClassName="page-item"
                                                                        pageLinkClassName="page-link"
                                                                        previousClassName="page-item"
                                                                        previousLinkClassName="page-link"
                                                                        nextClassName="page-item"
                                                                        nextLinkClassName="page-link"
                                                                        breakLabel="..."
                                                                        breakClassName="page-item"
                                                                        breakLinkClassName="page-link"
                                                                        pageCount={totalPages}
                                                                        marginPagesDisplayed={2}
                                                                        pageRangeDisplayed={5}
                                                                        onPageChange={handlePageChange}
                                                                        containerClassName="pagination"
                                                                        activeClassName="active"
                                                                    />
                                                                </div>

                                                                <div className='col-3 d-flex justify-content-end'>
                                                                    <div>
                                                                        <div className="input-group">
                                                                            <input type="text" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} className="form-control" placeholder="Search by device ID" />
                                                                            <button type='submit' className="btn btn-orange" onClick={handleSearch}>
                                                                                <i className="fa fa-search"></i>
                                                                            </button>
                                                                            <button type='submit' onClick={clearSearch} className="btn btn-secondary mx-1">
                                                                                <i className="fa fa-times"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    {e.eventKey == 'other' &&
                                                                        <div>
                                                                            <button className="btn btn btn-success mx-1 w-100" variant="primary" onClick={handleShow}>
                                                                                <i className="fa fa-plus"></i> Add
                                                                            </button>
                                                                        </div>
                                                                    }
                                                                    <button className='btn btn-sm btn-orange refresh' onClick={() => handleRefresh(e.eventKey)}><i className="fa fa-refresh" aria-hidden="true"></i></button>
                                                                </div>

                                                            </div>

                                                            <table className='workforce-table mt-2' >
                                                                <thead>
                                                                    <tr>
                                                                        <th>S.No</th>
                                                                        <th>Device ID</th>
                                                                        <th>Device Type</th>
                                                                        <th>Device Model</th>
                                                                        <th>Sim 1</th>
                                                                        <th>Sim 2</th>
                                                                        <th>SMS History </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        deviceList && deviceList.length > 0
                                                                            ?
                                                                            deviceList.map((m, i) => {
                                                                                return <>
                                                                                    {
                                                                                        e.eventKey == 'lynkgrid' && <tr key={i}>
                                                                                            <td>{((currentPage - 1) * 100) + (i + 1)}</td>
                                                                                            <td>{m._id}</td>
                                                                                            <td>{m.deviceName}</td>
                                                                                            <td></td>
                                                                                            <td>{(m.operator && m.sim) ? m.sim + "(" + m.operator + ")" : ""}</td>
                                                                                            <td>{(m.operator2 && m.sim2) ? m.sim2 + "(" + m.operator2 + ")" : ""}</td>
                                                                                            <td>{m.messageSentAt ? moment(m.messageSentAt).format("DD-MM-YYYY, h:mm:ss a") : ""}</td>

                                                                                        </tr>
                                                                                    }
                                                                                    {
                                                                                        e.eventKey == 'lynktrac' && <tr key={i}>
                                                                                            <td>{((currentPage - 1) * 100) + (i + 1)}</td>
                                                                                            <td>{m.client_id ? m.client_id : m.asset_id}</td>
                                                                                            <td>{m.deviceType ? m.deviceType : ""}</td>
                                                                                            <td>{m.deviceModel ? m.deviceModel : ""}</td>
                                                                                            <td>{(m.sim1type && m.sim1no) ? m.sim1no + "(" + m.sim1type + ")" : ""}</td>
                                                                                            <td>{(m.sim2type && m.sim2no) ? m.sim2no + "(" + m.sim21type + ")" : ""}</td>
                                                                                            <td>{m.messageSentAt ? moment(m.messageSentAt).format("DD-MM-YYYY, h:mm:ss a") : ""}</td>

                                                                                        </tr>
                                                                                    }
                                                                                    {
                                                                                        e.eventKey == 'other' && <tr key={i}>
                                                                                            <td>{((currentPage - 1) * 100) + (i + 1)}</td>
                                                                                            <td>{m.deviceID ? m.deviceID : "pending"}</td>
                                                                                            <td>{m.deviceType ? m.deviceType : ""}</td>
                                                                                            <td>{m.deviceModel ? m.deviceModel : ""}</td>
                                                                                            <td>{m.sim1 ? m.sim1 + "(" + m.simType1 + ")" : ""}</td>
                                                                                            <td>{m.sim2 ? m.sim2 + "(" + m.simType2 + ")" : ""}</td>
                                                                                            <td>{m.messageSentAt ? moment(m.messageSentAt).format("DD-MM-YYYY, h:mm:ss a") : ""}</td>

                                                                                        </tr>
                                                                                    }
                                                                                </>
                                                                            })
                                                                            :
                                                                            <tr>
                                                                                <td colSpan={100}>No Records</td>
                                                                            </tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </Tab>
                                            );
                                        });
                                        return tabss
                                    })()
                                    }
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton style={{ backgroundColor: '#FF7200', color: '#fff' }}>
                        <Modal.Title>Add Device</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit(handleAddDevice)}>
                            <div className='row'>
                                <div className="col-6 mb-2">
                                    <input className="form-control" type="text" placeholder="Device ID" {...register('deviceID', { required: true })} />
                                    <div className="mt-1">
                                        {errors.deviceID && <p className='error red_color'>Device ID is required</p>}
                                    </div>
                                </div>
                                <div className="col-6 mb-2">
                                    <input className="form-control" type="text" placeholder="Product" {...register('product', { required: true })} />
                                    <div className="mt-1">
                                        {errors.product && <p className='error red_color'>In Which product you want to add Device</p>}
                                    </div>
                                </div>
                                <div className="col-6 mb-2">
                                    <input className="form-control" type="text" placeholder="Device Type" {...register('deviceType', { required: true })} />
                                    <div className="mt-1">
                                        {errors.deviceType && <p className='error red_color'>Device Type is required</p>}
                                    </div>
                                </div>
                                <div className="col-6 mb-2">
                                    <input className="form-control" type="text" placeholder="Device Modal" {...register('deviceModel', { required: true })} />
                                    <div className="mt-1">
                                        {errors.deviceModel && <p className='error red_color'>Device Modal is required</p>}
                                    </div>
                                </div>
                                <div className="col-6 mb-2">
                                    <input className="form-control" type="text" placeholder="Sim Type 1" {...register('simType1', { required: true })} />
                                    <div className="mt-1">
                                        {errors.simType1 && <p className='error red_color'>required</p>}
                                    </div>
                                </div>
                                <div className="col-6 mb-2">
                                    <input className="form-control" type="text" placeholder="Sim Number 1" {...register('sim1', { required: true, maxLength: 13, minLength: 10, pattern: /^[+]?\d+$/ })} />
                                    <div className="mt-1">
                                        {errors.sim1 && <p className='error red_color'>required</p>}
                                    </div>
                                </div>
                                <div className="col-6 mb-2">
                                    <input className="form-control" type="text" placeholder="Sim Type 2" {...register('simType2', { required: true })} />
                                    <div className="mt-1">
                                        {errors.simType2 && <p className='error red_color'>required</p>}
                                    </div>
                                </div>
                                <div className="col-6 mb-2">
                                    <input className="form-control" type="text" placeholder="Sim Number 2" {...register('sim2', { required: true, maxLength: 13, minLength: 10, pattern: /^[+]?\d+$/ })} />
                                    <div className="mt-1">
                                        {errors.sim2 && <p className='error red_color'>required</p>}
                                    </div>
                                </div>
                                <div className="col-12 text-center d-flex justify-content-center">
                                    <button className='btn btn-secondary mt-3 mx-1' onClick={handleClose}>
                                        Close
                                    </button>
                                    <button className='btn btn-orange mt-3 mx-1'>Save</button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}
export default Device
